import React from 'react'

import classNames from 'classnames'

import Icon from '../Icon'

const BackToTop = () => {
  const classes = classNames('a-back-to-top')

  const backToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }

  return (
    <button className={classes} onClick={backToTop} type="button">
      <Icon className="a-back-to-top__icon" iconName="up" />
      <span className="a-back-to-top__label">Back to top</span>
    </button>
  )
}

export default BackToTop
