import React from 'react'

import classNames from 'classnames'

import Box from '../Box'
// import Button from '../Button'

const Popover = ({
  arrowPosition,
  buttonLabel,
  children,
  closeButton,
  detached = true,
  headline,
  isPopoverArrowMissing,
  paragraph,
}) => {
  const classes = classNames('m-popover',
    {
      [`-${arrowPosition}`]: arrowPosition,
      '-close-button': closeButton,
      '-detached': detached,
      '-without-arrow': isPopoverArrowMissing,
    },
  )

  return (
    <div className={classes}>
      <Box>
        <div className="m-popover__content">
          {(closeButton || headline) && (
            <div className="m-popover__head">
              {headline}

              {/* closeButton && (
                <Button
                  action="close"
                  aria-label="close popover"
                  icon="close"
                  isUiIcon
                  mode="integrated"
                />
              ) */}
            </div>
          )}
          {paragraph && (
            <div className="m-popover__paragraph">
              {paragraph}
            </div>
          )}
          {/* buttonLabel && (
            <Button
              action="confirm"
              label={buttonLabel}
              mode="primary"
            />
          ) */}
          {children}
        </div>
      </Box>
    </div>
  )
}

export default Popover
