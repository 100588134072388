import React from 'react'
import classNames from 'classnames'

const TopSearch = ({ children }) => {
  const classes = classNames('a-button', 'a-button--integrated', 'o-header__search-open')

  const openSearch = () => {
    document.body.classList.toggle('search-open')
    var searchInput = document.querySelector('.js-raised-search-input')
    var searchToggle = document.querySelector('.o-header__search-open')
    if (document.body.classList.contains('search-open')) {
      searchInput.focus()
    } else {
      searchToggle.focus()
    }
  }

  return (
    <button
      onClick={openSearch}
      type="button"
      className={classes}
      aria-haspopup="true"
      aria-expanded="false">
      <i className="a-icon a-button__icon boschicon-bosch-ic-search" title="Open search"></i>
      <i className="a-icon a-button__icon boschicon-bosch-ic-close" title="Close search"></i>
      <div className="a-button__label">Search</div>
    </button>
  )
}

export default TopSearch
