import React from 'react'

import classNames from 'classnames'

// import Text from '../Text'

const Divider = ({ paragraph }) => {
  const classes = classNames('a-divider',
    {
      [`-within-text`]: paragraph,
    },
  )

  return paragraph
    ? (
      <div className="container">
        {/* <Text content={paragraph} /> */}
        <hr className={classes} />
        {/* <Text content={paragraph} /> */}
      </div>
    ) : (
      <hr className={classes} />
    )
}

export default Divider
