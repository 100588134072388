import React, { useRef } from 'react'

import classNames from 'classnames'
import HTMLReactParser from 'html-react-parser'

import Box from '../Box'
// import Button from '../Button'
import Divider from '../Divider'
import Icon from '../Icon'

const Dialog = ({
  children,
  closeBtn,
  dialogBody,
  dialogCode,
  dialogId,
  headline,
  modal,
  optionalButton,
  title,
  variant,
}) => {
  const dialogRef = useRef()

  const classes = classNames('m-dialog',
    {
      [`m-dialog--${variant}`]: variant,
    },
  )

  const closeModal = () => {
    dialogRef.current.close()
  }

  return (
    <dialog
      className={classes}
      id={dialogId}
      ref={dialogRef}
    >
      <Box modal={modal}>
        {title && (
          <>
            <div className="m-dialog__header">
              {variant && (
                <Icon
                  iconName={`alert-${variant}`}
                  isUiIcon
                  titleText={variant}
                />
              )}
              <div className="m-dialog__title">{title}</div>
              {closeBtn && (
                <>
                  {/* <Button
                  action="close"
                  mode="integrated"
                  icon="close"
                  isUiIcon
                  aria-label="close dialog"
                /> */}
                  <button
                    className="a-button a-button--integrated -without-label"
                    aria-label="close dialog"
                    data-frok-action="close"
                    onClick={closeModal}
                    type="button"
                  >
                    <i className="a-icon a-button__icon ui-ic-close" title="Close dialog"></i>
                  </button>
                </>
              )}
            </div>
            <Divider />
          </>
        )}
        <div className="m-dialog__content">
          {headline && (
            <div className="m-dialog__headline">
              {headline}
            </div>
          )}
          <div className="m-dialog__body">
            {dialogBody || HTMLReactParser(children)}
          </div>
          {dialogCode && (
            <div className="m-dialog__code">
              {dialogCode}
            </div>
          )}
          {/*
          <div className="m-dialog__actions">
            {optionalButton && (
              <Button
                label="Optional button"
                mode="secondary"
                action="optional"
              />
            )}
            <Button label="Confirm" mode="primary" action="confirm" />
            <Button label="Cancel" mode="secondary" action="cancel" />
          </div>
          */}
        </div>
      </Box>
    </dialog>
  )
}

export default Dialog
