import React from 'react'

import classNames from 'classnames'

const Box = ({ children }) => {
  const classes = classNames('a-box', '-floating')

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Box
