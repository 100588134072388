import React from 'react'

import classNames from 'classnames'
import { truncate } from 'lodash'

const Review = ({ date, id, name, rating, text }) => {
  const classes = classNames('m-review')

  return (
    <div className={classes}>
      <h4 className="m-review__name">
        {name}
      </h4>
      <p>
        {date}
      </p>
      <p>
        {truncate(text, { length: 125 })}
      </p>
      <img src={`/img/trustpilot/${rating}${rating > 1 ? 'stars' : 'star'}.svg`} alt={`${rating} ${rating > 1 ? 'stars' : 'star'}`} width="110" />
    </div>
  )
}

export default Review
