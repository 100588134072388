import React from 'react'

import mountReact from '../shared/mountReact'

import Accordion from './Accordion'
import BackToTop from './BackToTop'
import Box from './Box'
import Dialog from './Dialog'
import Divider from './Divider'
import Icon from './Icon'
import Popover from './Popover'
import Review from './Review'
import TopSearch from './TopSearch'

mountReact(function ({ children, content, headline, id }) {
  return <Accordion content={content} headline={headline} id={id}>{children}</Accordion>
}, '.frok-accordion-mount')

mountReact(function () {
  return <BackToTop />
}, '.frok-back-to-top-mount')

mountReact(function (props) {
  return <Box {...props} />
}, '.frok-box')

mountReact(function ({
  children,
  closeBtn,
  dialogBody,
  dialogCode,
  dialogId,
  headline,
  modal,
  optionalButton,
  title,
  variant,
}) {
  return <Dialog
    closeBtn={closeBtn}
    dialogBody={dialogBody}
    dialogCode={dialogCode}
    dialogId={dialogId}
    headline={headline}
    modal={modal}
    optionalButton={optionalButton}
    title={title}
    variant={variant}
  >{children}</Dialog>
}, '.frok-dialog')

mountReact(function (props) {
  return <Divider {...props} />
}, '.frok-divider')

mountReact(function (props) {
  return <Icon {...props} />
}, '.frok-icon-mount')

mountReact(function (props) {
  return <Popover {...props} />
}, '.frok-popover')

mountReact(function ({ date, id, name, rating, text }) {
  return <Review date={date} id={id} name={name} rating={rating} text={text} />
}, '.frok-review')

mountReact(function () {
  return <TopSearch />
}, '.top-search-trigger')
